import logo from './KP.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="LOJAS KIPI" title="LOJAS KIPI" />
          <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        <p className="App-em-breve">
          O SITE JÁ DISPONÍVEL <a href="https://www.lojaskipi.com.br/">WWW.LOJASKIPI.COM.BR</a> 
        </p>
        <p>
          <small>18 DE ABRIL DE 2021</small>
        </p>
      </header>
    </div>
  );
}

export default App;
